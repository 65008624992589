.error-snackbar {
  .mdc-snackbar__surface {
    background-color: rgb(153, 27, 27) !important;
  }

  .mdc-snackbar__label {
    text-align: center !important;
    color: #fff !important;
  }
}

.success-snackbar {
  .mdc-snackbar__surface {
    background-color: rgb(22, 101, 52) !important;
  }

  .mdc-snackbar__label {
    text-align: center !important;
    color: #fff !important;
  }
}
