/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import "./components/table";
@import "./components/form";
@import "./components/snackbar";

.breadcrumb-chevron-icon svg {
  width: 18px;
}
